import * as React from 'react'
// import { Link } from "gatsby"
// import { StaticImage } from 'gatsby-plugin-image'
import styled from '@emotion/styled'
import Layout from '../components/layout'
import Seo from '../components/seo'
import DestinationInnPart from '../components/home/destinationInn'
// import IframeResizer from "iframe-resizer-react"

const SearchByAvailability = () => {
  const searchByAvailLink = 'https://v2.reservationkey.com/85390/reservedestination'
  const Wrapper = styled.div`
    text-align: center;
    margin-bottom: 64px;
  `
  return (
    <Layout>
      <Seo
        title="Search by availability"
        description="Destination Inn, premium lodging in Radium Hot Springs"
        canonical="https://destinationinn.ca/search-by-availability/"
      />

      <Wrapper>
        <DestinationInnPart title="SEARCH BY AVAILABILITY" lastLine="" />
        <iframe
          title="Search for a room by Availability"
          name="publicpage"
          log
          src={searchByAvailLink}
          style={{
            width: '1px',
            minWidth: '100%',
            minHeight: '1100px',
            border: 'none',
            frameBorder: '0',
          }}
        />
      </Wrapper>
    </Layout>
  )
}

export default SearchByAvailability
